import { PhoneNumberUtil } from 'google-libphonenumber';
import { isMonthBeforeDay } from './isMonthBeforeDay';

const isValidDate = (
  date: string,
  minYear: number,
  maxYear: number
): boolean => {
  const monthBeforeDay = isMonthBeforeDay();
  const dateParts = date?.split('/');
  if (dateParts?.length === 3) {
    const [firstPart, secondPart, year] = dateParts;
    const month = parseInt(monthBeforeDay ? firstPart : secondPart, 10);
    const day = parseInt(monthBeforeDay ? secondPart : firstPart, 10);
    const parsedYear = parseInt(year, 10);
    const isValidYear =
      !Number.isNaN(parsedYear) &&
      parsedYear >= minYear &&
      parsedYear <= maxYear;
    const isValidMonth = month >= 1 && month <= 12;
    const isValidDay = day >= 1 && day <= 31;
    return isValidYear && isValidMonth && isValidDay;
  }
  return false;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputValidator = (name: string, value: any) => {
  switch (name) {
    case 'agencyName':
    case 'name':
    case 'message':
    case 'address':
    case 'zip':
    case 'postalCode':
    case 'agentFirstName':
    case 'agentLastName':
      return value.length >= 3;
    case 'firstName':
    case 'lastName':
    case 'gender':
      return !!value && value.length >= 1;
    case 'email':
      // eslint-disable-next-line
      const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegEx.test(String(value).toLowerCase());
    case 'invoicingEmail':
    case 'agencyEmail':
    case 'agentEmail':
      // eslint-disable-next-line
      const agentEmailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return agentEmailRegEx.test(String(value).toLowerCase());
    case 'phoneNumber':
      // eslint-disable-next-line no-case-declarations
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (String(value).length > 4) {
        // eslint-disable-next-line no-useless-catch
        try {
          const phoneNumberObject = phoneUtil.parse(String(value));
          if (phoneUtil.isValidNumber(phoneNumberObject)) {
            return true;
          }
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      }
      return false;
    case 'countryCode':
      return true;
    case 'jobTitle':
    case 'country':
    case 'state':
    case 'city':
    case 'street':
    case 'expectedAmount':
      return value.length !== 0 && !Number.isNaN(value);
    case 'password':
    case 'address2':
    case 'addressLine2':
    case 'building':
    case 'countrySelector':
    case 'countryText':
    case 'confirm':
    case 'color':
    case 'customerId':
    case 'isCustomerIdCheck':
    case 'language':
      return true;
    case 'dateOfBirth':
      // eslint-disable-next-line no-case-declarations
      return isValidDate(
        value,
        new Date().getFullYear() - 120,
        new Date().getFullYear()
      );
    case 'nationality':
      return value instanceof Object;
    case 'agencyId':
      return value.length >= 3 && value.length <= 12 && /^\d+$/.test(value);
    case 'ukAgencyId':
      return /^[a-zA-Z0-9]{5,6}$/.test(value) || /^\d{7}$/.test(value);
    case 'abtaNumber':
      return /^[a-zA-Z0-9]{5,6}$/.test(value);
    case 'iataNumber':
      return value.length >= 8 && value.length <= 12 && /^\d+$/.test(value);
    case 'location':
      return true;
    case 'vatNumber':
      return value.length <= 14 && /^[a-zA-Z0-9]+$/.test(value);
    case 'groupTourOperator':
      return typeof value === 'boolean';
    case 'registrationNumber':
      return value.length !== 0;
    case 'NumberCatalogsCoastal':
      return true;
    case 'passportExpDate':
      // eslint-disable-next-line no-case-declarations
      return isValidDate(
        value,
        new Date().getFullYear(),
        new Date().getFullYear() + 10
      );

    case 'passportIssueDate':
      // eslint-disable-next-line no-case-declarations
      return isValidDate(
        value,
        new Date().getFullYear() - 10,
        new Date().getFullYear()
      );
    case 'tourOperatorCooperation':
    case 'website':
      return true;

    default:
      return false;
  }
};

export default inputValidator;
